import React, { useState, useEffect, useRef } from 'react';
import { Power, Cpu, Zap, Mail, Github, RadioTower } from 'lucide-react';

import emailjs from '@emailjs/browser';

// 프로젝트 이미지 import
import project1 from './images/project1.webp';
import project2 from './images/project2.webp';
import project3 from './images/project3.webp';
// ContactForm 컴포넌트 정의
const ContactForm = () => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');
  const formRef = useRef();

  const sendEmail = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setSuccess(false);

    try {
      const result = await emailjs.sendForm(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        formRef.current,
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY
      );

      if (result.text === 'OK') {
        setSuccess(true);
        formRef.current.reset();
      }
    } catch (error) {
      setError('메일 전송에 실패했습니다. 다시 시도해주세요.');
      console.error('Email send error:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="max-w-2xl mx-auto">
      <form ref={formRef} onSubmit={sendEmail} className="space-y-6">
        <div>
          <label className="block text-gray-400 mb-2">프로젝트 종류</label>
          <select 
            name="project_type"
            className="w-full bg-gray-800 border border-gray-700 rounded-lg px-4 py-2 text-gray-300 focus:outline-none focus:border-cyan-500"
            required
          >
            <option value="custom_device">커스텀 전자기기 제작</option>
            <option value="iot_system">IoT 시스템 개발</option>
            <option value="prototype">프로토타입 제작</option>
            <option value="consulting">기술 자문</option>
          </select>
        </div>
        <div>
          <label className="block text-gray-400 mb-2">이름</label>
          <input 
            type="text"
            name="from_name"
            className="w-full bg-gray-800 border border-gray-700 rounded-lg px-4 py-2 text-gray-300 focus:outline-none focus:border-cyan-500"
            required
          />
        </div>
        <div>
          <label className="block text-gray-400 mb-2">이메일</label>
          <input 
            type="email"
            name="from_email"
            className="w-full bg-gray-800 border border-gray-700 rounded-lg px-4 py-2 text-gray-300 focus:outline-none focus:border-cyan-500"
            required
          />
        </div>
        <div>
          <label className="block text-gray-400 mb-2">프로젝트 설명</label>
          <textarea 
            name="message"
            className="w-full bg-gray-800 border border-gray-700 rounded-lg px-4 py-2 text-gray-300 focus:outline-none focus:border-cyan-500"
            rows="4"
            required
          ></textarea>
        </div>
        <button 
          type="submit"
          disabled={loading}
          className="w-full bg-gradient-to-r from-cyan-500 to-purple-500 text-white py-3 rounded-lg hover:opacity-90 transition-opacity disabled:opacity-50"
        >
          {loading ? '전송 중...' : '문의하기'}
        </button>
        {error && (
          <p className="text-red-500 text-sm text-center">{error}</p>
        )}
        {success && (
          <p className="text-green-500 text-sm text-center">
            메일이 성공적으로 전송되었습니다.
          </p>
        )}
      </form>
    </div>
  );
};


const MainPage = () => {
  const [scrollY, setScrollY] = useState(0);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const projects = [
    {
      title: "자동분류 스마트 쓰레기통",
      description: "AI와 센서를 활용한 자동 분리수거 시스템",
      longDesc: "카메라와 머신러닝을 활용하여 쓰레기를 자동으로 분류하고 수거하는 스마트 시스템입니다. 분류된 쓰레기는 자동으로 해당 수거함으로 이동됩니다.",
      tags: ["AI", "임베디드", "센서", "모터제어"],
      techStack: ["ESP32", "TensorFlow Lite", "서보모터", "OpenCV"],
      image: project1,
      githubUrl: "https://github.com/username/smart-bin"
    },
    {
      title: "마이크로비트 드로잉 봇",
      description: "프로그래밍 가능한 자동 드로잉 로봇",
      longDesc: "마이크로비트를 활용한 프로그래밍 교육용 드로잉 로봇입니다. 블록 코딩으로 간단히 프로그래밍하여 다양한 그림을 그릴 수 있습니다.",
      tags: ["교육", "로보틱스", "블록코딩"],
      techStack: ["BBC micro:bit", "MakeCode", "DC모터", "3D프린팅"],
      image: project2,
      githubUrl: "https://github.com/username/microbit-drawer"
    },
    {
      title: "IoT 솔레노이드 락",
      description: "원격 제어 가능한 전자식 잠금장치",
      longDesc: "웹 인터페이스를 통해 원격으로 제어할 수 있는 전자식 잠금장치입니다. 실시간 상태 모니터링과 접근 기록 관리가 가능합니다.",
      tags: ["IoT", "보안", "웹개발"],
      techStack: ["ESP8266", "MQTT", "솔레노이드", "웹소켓"],
      image: project3,
      githubUrl: "https://github.com/username/iot-lock"
    }
  ];

  return (
    <div className="bg-gray-900 text-gray-100 min-h-screen">
      {/* 네비게이션 바 */}
      <nav className="fixed w-full bg-gray-900 bg-opacity-90 z-50 backdrop-blur">
        <div className="container mx-auto px-4 py-3">
          <div className="flex justify-between items-center">
            <div className="flex items-center space-x-2">
              <RadioTower className="w-8 h-8 text-cyan-400" />
              <span className="text-2xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-cyan-400 to-purple-500">
                21세기 전파사
              </span>
            </div>
            <div className="hidden md:flex space-x-8">
              <a href="#projects" className="hover:text-cyan-400 transition-colors">Projects</a>
              <a href="#skills" className="hover:text-cyan-400 transition-colors">Skills</a>
              <a href="#contact" className="hover:text-cyan-400 transition-colors">Contact</a>
            </div>
            <button 
              className="md:hidden text-cyan-400"
              onClick={() => setIsMenuOpen(!isMenuOpen)}
            >
              <Power className="w-6 h-6" />
            </button>
          </div>
        </div>
      </nav>

      {/* 모바일 메뉴 */}
      {isMenuOpen && (
        <div className="fixed inset-0 bg-gray-900 z-40 md:hidden">
          <div className="flex flex-col items-center justify-center h-full space-y-8">
            <a href="#projects" className="text-2xl hover:text-cyan-400">Projects</a>
            <a href="#skills" className="text-2xl hover:text-cyan-400">Skills</a>
            <a href="#contact" className="text-2xl hover:text-cyan-400">Contact</a>
          </div>
        </div>
      )}

      {/* 히어로 섹션 */}
      <div className="h-screen flex items-center justify-center relative overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-b from-gray-900 to-purple-900 opacity-50" />
        <div 
          className="absolute inset-0" 
          style={{
            backgroundImage: 'url("data:image/svg+xml,%3Csvg width="60" height="60" viewBox="0 0 60 60" xmlns="http://www.w3.org/2000/svg"%3E%3Cg fill="none" fill-rule="evenodd"%3E%3Cg fill="%239C92AC" fill-opacity="0.1"%3E%3Cpath d="M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z"/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")'
          }}
        />
        <div className="container mx-auto px-4 text-center relative">
          <h1 className="text-5xl md:text-7xl font-bold mb-6 animate-pulse">
            <span className="text-transparent bg-clip-text bg-gradient-to-r from-cyan-400 to-purple-500">
              상상을 현실로
            </span>
          </h1>
          <p className="text-xl md:text-2xl mb-8 text-gray-300">
            전자공학 • 임베디드 • IoT • 3D프린팅
          </p>
          <div className="flex justify-center space-x-4">
            <a 
              href="#projects"
              className="bg-cyan-500 hover:bg-cyan-600 text-white px-8 py-3 rounded-lg transition-colors"
            >
              프로젝트 보기
            </a>
            <a 
              href="#contact"
              className="border border-purple-500 hover:bg-purple-500 px-8 py-3 rounded-lg transition-colors"
            >
              연락하기
            </a>
          </div>
        </div>
      </div>

      {/* 프로젝트 섹션 */}
      <section id="projects" className="py-20 bg-gray-800">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl md:text-4xl font-bold mb-12 text-center">
            <span className="text-transparent bg-clip-text bg-gradient-to-r from-cyan-400 to-purple-500">
              Featured Projects
            </span>
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {projects.map((project, index) => (
              <div 
                key={index}
                className="bg-gray-900 rounded-lg overflow-hidden hover:shadow-lg hover:shadow-cyan-500/20 transition-all group"
              >
                <div className="relative">
                  <img 
                    src={project.image}
                    alt={project.title}
                    className="w-full h-48 object-cover"
                  />
                  <div className="absolute inset-0 bg-gradient-to-t from-gray-900 to-transparent opacity-0 group-hover:opacity-100 transition-opacity" />
                </div>
                <div className="p-6">
                  <h3 className="text-xl font-bold mb-2 text-transparent bg-clip-text bg-gradient-to-r from-cyan-400 to-purple-500">
                    {project.title}
                  </h3>
                  <p className="text-gray-400 mb-4">{project.description}</p>
                  <p className="text-gray-500 text-sm mb-4">{project.longDesc}</p>
                  <div className="mb-4">
                    <h4 className="text-sm font-semibold text-gray-300 mb-2">사용 기술:</h4>
                    <div className="flex flex-wrap gap-2">
                      {project.techStack.map((tech, techIndex) => (
                        <span 
                          key={techIndex}
                          className="px-2 py-1 bg-gray-800 rounded text-xs text-purple-400"
                        >
                          {tech}
                        </span>
                      ))}
                    </div>
                  </div>
                  <div className="flex flex-wrap gap-2 mb-4">
                    {project.tags.map((tag, tagIndex) => (
                      <span 
                        key={tagIndex}
                        className="px-3 py-1 bg-gray-800 rounded-full text-sm text-cyan-400"
                      >
                        {tag}
                      </span>
                    ))}
                  </div>
                  <a
                    href={project.githubUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="inline-flex items-center text-sm text-gray-400 hover:text-cyan-400 transition-colors"
                  >
                    <Github className="w-4 h-4 mr-2" />
                    소스 코드 보기
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* 스킬 섹션 */}
      <section id="skills" className="py-20 bg-gray-900">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl md:text-4xl font-bold mb-12 text-center">
            <span className="text-transparent bg-clip-text bg-gradient-to-r from-cyan-400 to-purple-500">
              Technical Skills
            </span>
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="p-6 bg-gray-800 rounded-lg hover:shadow-lg hover:shadow-purple-500/20 transition-all">
              <Cpu className="w-12 h-12 text-purple-500 mb-4" />
              <h3 className="text-xl font-bold mb-4">임베디드 시스템</h3>
              <ul className="space-y-2 text-gray-400">
                <li>• ESP32/ESP8266</li>
                <li>• Arduino</li>
                <li>• 센서 인터페이싱</li>
                <li>• 실시간 시스템</li>
              </ul>
            </div>
            <div className="p-6 bg-gray-800 rounded-lg hover:shadow-lg hover:shadow-cyan-500/20 transition-all">
              <Zap className="w-12 h-12 text-cyan-400 mb-4" />
              <h3 className="text-xl font-bold mb-4">전자공학</h3>
              <ul className="space-y-2 text-gray-400">
                <li>• 회로 설계</li>
                <li>• PCB 디자인</li>
                <li>• 신호 처리</li>
                <li>• 전력 관리</li>
              </ul>
            </div>
            <div className="p-6 bg-gray-800 rounded-lg hover:shadow-lg hover:shadow-purple-500/20 transition-all">
              <Github className="w-12 h-12 text-purple-500 mb-4" />
              <h3 className="text-xl font-bold mb-4">소프트웨어</h3>
              <ul className="space-y-2 text-gray-400">
                <li>• C/C++</li>
                <li>• Python</li>
                <li>• IoT 프로토콜</li>
                <li>• 웹 서비스</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      {/* 연락처 섹션 */}
      <section id="contact" className="py-20 bg-gray-800">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl md:text-4xl font-bold mb-12 text-center">
            <span className="text-transparent bg-clip-text bg-gradient-to-r from-cyan-400 to-purple-500">
              Let's Connect
            </span>
          </h2>

          <ContactForm />
          {/* <div className="max-w-2xl mx-auto">
            <div className="bg-gray-900 rounded-lg p-8">
              <form className="space-y-6">
                <div>
                  <label className="block text-gray-400 mb-2">프로젝트 종류</label>
                  <select className="w-full bg-gray-800 border border-gray-700 rounded-lg px-4 py-2 text-gray-300 focus:outline-none focus:border-cyan-500">
                    <option>커스텀 전자기기 제작</option>
                    <option>IoT 시스템 개발</option>
                    <option>프로토타입 제작</option>
                    <option>기술 자문</option>
                  </select>
                </div>
                <div>
                  <label className="block text-gray-400 mb-2">이메일</label>
                  <input 
                    type="email"
                    className="w-full bg-gray-800 border border-gray-700 rounded-lg px-4 py-2 text-gray-300 focus:outline-none focus:border-cyan-500"
                    placeholder="your@email.com"
                  />
                </div>
                <div>
                  <label className="block text-gray-400 mb-2">프로젝트 설명</label>
                  <textarea 
                    className="w-full bg-gray-800 border border-gray-700 rounded-lg px-4 py-2 text-gray-300 focus:outline-none focus:border-cyan-500"
                    rows="4"
                    placeholder="프로젝트에 대해 설명해주세요"
                  ></textarea>
                </div>
                <button 
                  type="submit"
                  className="w-full bg-gradient-to-r from-cyan-500 to-purple-500 text-white py-3 rounded-lg hover:opacity-90 transition-opacity"
                >
                  문의하기
                </button>
              </form>
            </div>
          </div> */}
        </div>
      </section>

      {/* 푸터 */}
      <footer className="bg-gray-900 py-8">
        <div className="container mx-auto px-4 text-center text-gray-400">
          <div className="flex justify-center space-x-6 mb-4">
            <a href="mailto:jeonpasas@gmail.com" className="hover:text-cyan-400">
              <Mail className="w-6 h-6" />
            </a>
            <a href="https://github.com/" className="hover:text-cyan-400">
              <Github className="w-6 h-6" />
            </a>
          </div>
          <p>© 2025 21세기 전파사. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
};

export default MainPage;